import React from "react";

export const JobSvg = ({ COLOR }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.007 21H9.605C6.02 21 4.228 21 3.114 19.865C2 18.73 2 16.903 2 13.25C2 9.597 2 7.77 3.114 6.635C4.228 5.5 6.02 5.5 9.605 5.5H13.408C16.993 5.5 18.786 5.5 19.9 6.635C20.757 7.508 20.954 8.791 21 11"
        stroke={COLOR}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.017 20.023L22 22M16 5.5L15.9 5.19C15.405 3.65 15.158 2.88 14.569 2.44C13.979 2 13.197 2 11.63 2H11.367C9.802 2 9.019 2 8.43 2.44C7.84 2.88 7.593 3.65 7.098 5.19L7 5.5M21.053 17.526C21.0609 17.0579 20.9755 16.5929 20.8018 16.1581C20.6281 15.7234 20.3696 15.3276 20.0414 14.9937C19.7131 14.6599 19.3217 14.3948 18.8899 14.2138C18.4582 14.0328 17.9947 13.9396 17.5265 13.9396C17.0583 13.9396 16.5948 14.0328 16.1631 14.2138C15.7313 14.3948 15.3399 14.6599 15.0116 14.9937C14.6834 15.3276 14.4249 15.7234 14.2512 16.1581C14.0775 16.5929 13.9921 17.0579 14 17.526C14.0156 18.451 14.394 19.3329 15.0536 19.9815C15.7133 20.6301 16.6014 20.9936 17.5265 20.9936C18.4516 20.9936 19.3397 20.6301 19.9994 19.9815C20.659 19.3329 21.0374 18.451 21.053 17.526Z"
        stroke={COLOR}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
