import React from "react";

export const DashboardSvg = ({ COLOR }) => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.9394 5.7722V5.77449V7.83283C10.9394 8.92979 10.651 9.68535 10.1677 10.1686C9.68438 10.6519 8.92882 10.9403 7.83185 10.9403H5.77352C4.67655 10.9403 3.92099 10.6519 3.43769 10.1686C2.9544 9.68535 2.66602 8.92979 2.66602 7.83283V5.77449C2.66602 4.67753 2.95439 3.92225 3.43865 3.43906C3.9231 2.95569 4.68138 2.66699 5.78435 2.66699H7.84268C8.93983 2.66699 9.69502 2.95546 10.1768 3.43828C10.6583 3.92089 10.9444 4.67566 10.9394 5.7722Z"
        stroke={COLOR}
      />
      <path
        opacity="0.4"
        d="M18.168 2.66699H20.2264C21.3233 2.66699 22.0789 2.95538 22.5622 3.43867C23.0455 3.92196 23.3339 4.67752 23.3339 5.77449V7.83283C23.3339 8.92979 23.0455 9.68535 22.5622 10.1686C22.0789 10.6519 21.3233 10.9403 20.2264 10.9403H18.168C17.0711 10.9403 16.3155 10.6519 15.8322 10.1686C15.3489 9.68535 15.0605 8.92979 15.0605 7.83283V5.77449C15.0605 4.67752 15.3489 3.92196 15.8322 3.43867C16.3155 2.95538 17.0711 2.66699 18.168 2.66699Z"
        stroke={COLOR}
      />
      <path
        d="M18.168 15.0488H20.2264C21.3233 15.0488 22.0789 15.3372 22.5622 15.8205C23.0455 16.3038 23.3339 17.0594 23.3339 18.1563V20.2147C23.3339 21.3116 23.0455 22.0672 22.5622 22.5505C22.0789 23.0338 21.3233 23.3222 20.2264 23.3222H18.168C17.0711 23.3222 16.3155 23.0338 15.8322 22.5505C15.3489 22.0672 15.0605 21.3116 15.0605 20.2147V18.1563C15.0605 17.0594 15.3489 16.3038 15.8322 15.8205C16.3155 15.3372 17.0711 15.0488 18.168 15.0488Z"
        stroke={COLOR}
      />
      <path
        opacity="0.4"
        d="M10.9394 18.1649V18.1672V20.2255C10.9394 21.3225 10.651 22.078 10.1677 22.5613C9.68438 23.0446 8.92882 23.333 7.83185 23.333H5.77352C4.67655 23.333 3.92128 23.0446 3.43809 22.5604C2.95472 22.0759 2.66602 21.3176 2.66602 20.2147V18.1563C2.66602 17.0594 2.95439 16.3041 3.43865 15.8209C3.9231 15.3375 4.68138 15.0488 5.78435 15.0488H7.84268C8.93982 15.0488 9.69473 15.3373 10.1764 15.8211C10.658 16.3048 10.9444 17.0623 10.9394 18.1649Z"
        stroke={COLOR}
      />
    </svg>
  );
};
