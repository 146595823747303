import React from "react";

export const PersonBagsvg = ({ COLOR }) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.2564 20.1663H9.97125C6.685 20.1663 5.04233 20.1663 4.02117 19.1259C3 18.0855 3 16.4108 3 13.0622C3 9.71359 3 8.03884 4.02117 6.99842C5.04233 5.95801 6.685 5.95801 9.97125 5.95801H13.4573C16.7436 5.95801 18.3872 5.95801 19.4083 6.99842C20.1939 7.79867 20.3745 8.97476 20.4167 10.9997"
        stroke={COLOR}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.834 5.95833L15.7423 5.67417C15.2886 4.2625 15.0622 3.55667 14.5222 3.15333C13.9814 2.75 13.2646 2.75 11.8282 2.75H11.5871C10.1525 2.75 9.43473 2.75 8.89482 3.15333C8.35398 3.55667 8.12757 4.2625 7.67382 5.67417L7.58398 5.95833"
        stroke={COLOR}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.2365 20.1663H13.4448C16.731 20.1663 18.3737 20.1663 19.3948 19.1259C20.416 18.0855 20.416 16.4108 20.416 13.0622C20.416 9.71359 20.416 8.03884 19.3948 6.99842C18.3737 5.95801 16.731 5.95801 13.4448 5.95801H9.95868C6.67243 5.95801 5.02885 5.95801 4.00768 6.99842C3.2221 7.79867 3.04152 8.97476 2.99935 10.9997"
        stroke={COLOR}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.58203 5.95833L7.6737 5.67417C8.12745 4.2625 8.35386 3.55667 8.89378 3.15333C9.43461 2.75 10.1514 2.75 11.5879 2.75H11.8289C13.2635 2.75 13.9813 2.75 14.5212 3.15333C15.062 3.55667 15.2884 4.2625 15.7422 5.67417L15.832 5.95833"
        stroke={COLOR}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
